import { useEffect, useState } from "react";
import moment from "moment";
import { mettingCollRef, scheduleAdjustmentColRef } from "../config/firebaseRef";

function useAppyGroup(user, db) {
  const [listPrimary, setListPrimary] = useState([]);
  const [listArchive, setListArchive] = useState([]);
  const [listMeetingGroup, setListMeetingGroup] = useState([]);
  const [listSettingShareUrl, setListSettingShareUrl] = useState([]);
  const [listLiveUrl, setListLiveUrl] = useState([]);
  const [listArchiveUrl, setListArchiveUrl] = useState([]);

  useEffect(() => {
    if (!user?.user_id)
      return;
    let allList = [], primary = [], archive = [], liveUrl = [], archiveUrl = [];

    const sub = mettingCollRef(db)
      .where("isDeleted", "==", false)
      .where("userIds", "array-contains", user?.user_id)
      .orderBy("updatedAt", "desc")
      .onSnapshot(snapshot => {
        if (snapshot.docs?.length) {
          allList = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
          primary = allList.filter(list => list?.automaticTimes?.includes(user.user_id));
          archive = allList.filter(list => !list?.automaticTimes?.includes(user.user_id));
          setListPrimary(primary);
          setListArchive(archive);
          setListMeetingGroup(allList);
        }
      });
    const sub2 = scheduleAdjustmentColRef(user.user_id, db)
      .onSnapshot(snapshot => {
        const data = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        let arr = [];
        arr = data.filter(item => item?.nameSetting && item?.nameSetting?.replace(/\s+/g, "") !== "" && !item?.isDelete);
        liveUrl = arr.filter(item => moment(item?.exp).isAfter(Date.now()));
        archiveUrl = arr.filter(item => moment(item?.exp).isBefore(Date.now()));
        setListArchiveUrl(archiveUrl);
        setListLiveUrl(liveUrl);
        setListSettingShareUrl(arr);
      });

    return () => {
      if (sub)
        sub();
      if (sub2)
        sub2();
    };
    // eslint-disable-next-line
  }, [user]);

  return { listPrimary, listArchive, listMeetingGroup, listSettingShareUrl, listArchiveUrl, listLiveUrl };
}

export default useAppyGroup;
