import React from "react";
import { Col, Row } from "antd";
import { ReactComponent as SettingIcon } from "../../assets/iconSetting.svg";
import faviconFetch from "favicon-fetch";
import styles from "../../styles/ListApps.module.scss";

function ListApps({ user, listApps, title, onClickSetting, noResult, hiddenApp, listOther }) {
  return (
    <div className={styles.rootListApps}>

      {
        !hiddenApp && user?.user_id &&
        <div className={styles.topListApps}>
          <div>{title || "Setting"}</div>
          <button className={styles.iconButton} onClick={onClickSetting}>
            <SettingIcon />
          </button>
        </div>
      }

      {
        (!listApps.length && !listOther.length) &&
        <div className={styles.noResultListApps}>{noResult || "No data"}</div>
      }
      <Row>
        {
          listApps?.length > 0 && listApps.map((app, index) => {
            return (
              <Col key={index} className="gutter-row" span={8}>

                <div className={styles.itemListApps}
                     onClick={() => {
                       window.open(app.url, "_blank");
                     }}
                     onDoubleClick={() => {
                       window.open(app.url, "_blank");
                     }}
                >
                  <img className={styles.imgListApps} src={app?.icon} alt="" />
                  <div className={styles.nameListApps}>{app.name}</div>
                </div>

              </Col>
            );
            }
          )
        }
      </Row>
      {
        listOther?.length > 0 &&
        <div>
          <div className={styles.lineListApps} />
          <Row gutter={[8, 8]}>
            {
              listOther.map((app, index) =>
                <Col key={index} className="gutter-row" span={8}>
                  <div className={styles.itemListApps}
                       onClick={() => {
                         window.open(app.url, "_blank");
                       }}
                  >
                    <img className={styles.imgListApps}
                         src={app?.url ? faviconFetch({ uri: app.url }) : app?.icon}
                         alt={""} />
                    <div className={styles.nameListApps}>{app.name}</div>
                  </div>

                </Col>
              )
            }
          </Row>
        </div>
      }
    </div>
  );
}

export default ListApps;

