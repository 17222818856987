import React, { useCallback, useState } from "react";
import { isMobileOnly } from "react-device-detect";
import { Dialog, DialogActions, DialogContent, DialogTitle, Paper, RootRef } from "@material-ui/core";
import AvatarEditor from "react-avatar-editor";
import { useDropzone } from "react-dropzone";
import { Button } from "antd";


function PaperDropzone(props) {
  const onDrop = useCallback(acceptedFiles => {
    props.onDrop(acceptedFiles);
  }, []);
  const accept = "image/jpeg, image/png";
  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, accept });
  const { ref, ...rootProps } = getRootProps(
    {
      onClick: event => {
        event.stopPropagation();
      }
    }
  );
  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          open();
        }}
        style={{ marginBottom: 10 }}
      >選択する</Button>
      <RootRef rootRef={ref}>
        <Paper {...rootProps}>
          <input {...getInputProps()} />
          {props.children}
        </Paper>
      </RootRef>
    </div>
  );
}

function DialogChangeAvatar({ setOpenAvatarUploader, openAvatarUploader, user, storage, onUpdateAvatar }) {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [scale, setScale] = useState(1);
  const [urlAvatar, setUrlAvatar] = useState(null);
  let editor = "";
  const setEditorRef = (ed) => {
    editor = ed;
  };
  const hiddenAvatarUploader = () => {
    setOpenAvatarUploader(false);
  };
  const handleScale = e => {
    setScale(parseFloat(e.target.value));
  };

  const handleDrop = dropped => {
    const firstFile = dropped[0];
    setImage(firstFile);
  };
  const saveAvatar = async () => {
    try {
      setLoading(true);
      if (image === null || !user?.user_id || loading)
        return;

      if (setEditorRef) {

        //upload image
        const dataUrl = editor.getImage().toDataURL();
        const result = await fetch(dataUrl);
        const blob = await result.blob();
        const storageRef = storage.ref();
        const imgRef = storageRef.child(`avatar/${user.user_id}/${Date.now()}_${image.name}`);
        const snap = await imgRef.put(blob);
        const url = await snap.ref.getDownloadURL();
        onUpdateAvatar(url);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      setImage(null);
      setOpenAvatarUploader(false);
    }
  };

  return (
    <Dialog
      open={openAvatarUploader}
      scroll="body"
      aria-labelledby="scroll-dialog-title"
      fullScreen={isMobileOnly}
    >
      <div className="bgWhite">
        <DialogTitle id="scroll-dialog-title">プロフィール写真を選択</DialogTitle>
        <DialogContent>

          <PaperDropzone
            onDrop={handleDrop}
          >
            <div className="center" style={{ marginBottom: 15 }}>
              <AvatarEditor
                ref={setEditorRef}
                width={220}
                height={220}
                borderRadius={220}
                color={[255, 255, 255, 0.8]}
                rotate={0}
                image={image || user.avatar}
                scale={scale}

              />
            </div>
          </PaperDropzone>
          {
            image !== null && <div>
              Zoom:
              <input
                name="scale"
                type="range"
                onChange={handleScale}
                min={"0.1"}
                max="2"
                step="0.01"
                defaultValue="1"
                disabled={loading}
              />
            </div>
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={hiddenAvatarUploader}
                  color="primary">
            キャンセル
          </Button>
          <Button
            onClick={saveAvatar}
            disabled={loading || image === null}
            className="btnPrimary">
            {loading ? "アップロード中…" : "保存する"}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default DialogChangeAvatar;
