import {
  CALENDAR_COLLECTION,
  GLOBALNAVIGATION_COLLECTION,
  MEETING_COLLECTION,
  RECOMMEND_COLLECTION,
  USER_COLLECTION
} from "./constants";

export const barRef = (userId, db) => {
  return db.collection(CALENDAR_COLLECTION)
    .doc(userId)
    .collection("announcement")
    .doc("bar");
};

export const boardRef = (userId, db) => {
  return db.collection(CALENDAR_COLLECTION)
    .doc(userId)
    .collection("announcement")
    .doc("board");
};

export const notificationsColRef = (userId, db) => {
  return db.collection(CALENDAR_COLLECTION)
    .doc(userId)
    .collection("notifications");
};

export const scheduleAdjustmentColRef = (userId, db) => {
  return db.collection(CALENDAR_COLLECTION)
    .doc(userId)
    .collection("scheduleAdjustment");
};

export const mettingCollRef = (db) => {
  return db.collection(MEETING_COLLECTION);
};
export const recommendCollRef = (db) => {
  return db.collection(RECOMMEND_COLLECTION);
};

export const globalNavigationCollRef = (db) => {
  return db.collection(GLOBALNAVIGATION_COLLECTION);
};

export const userNavigationCollRef = (userId, db) => {
  return db.collection(USER_COLLECTION)
    .doc(userId)
    .collection("navigations");
};

export const noticeDocRef = (userId, db, noticeId) => {
  return db.collection(CALENDAR_COLLECTION)
    .doc(userId)
    .collection("notifications")
    .doc(noticeId);
};
