import React, { useState } from "react";
import { Avatar, IconButton } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { IS_PROD } from "../../config/constants";
import styles from "../../styles/AccountPopup.module.scss";
import { Tooltip } from "antd";
import DialogChangeAvatar from "./DialogChangeAvatar";


function AccountPopup({ user, switchAccount, onClickLogOut, intl, onClickAccount, storage, onUpdateAvatar }) {
  const [openAvatarUploader, setOpenAvatarUploader] = useState(false);
  const handleClickAccount = () => {
    window.open(`https://${IS_PROD ? "myaccount" : "myaccount-stg"}.geniam.com/account`, "_blank");
  };
  const onClickPlanManagement = () => {
    window.open(`https://${IS_PROD ? "myaccount" : "myaccount-stg"}.geniam.com/account/subscription`, "_blank");
  };
  const showAvatarUploader = () => {
    setOpenAvatarUploader(true);
  };


  return (
    <div className={styles.rootAccountPopup}>
      <div className={styles.itemAvatar}>
        <Tooltip title={intl["account.change"] || "Change Avata"}>
          <IconButton
            id={"user_avatar_button"}
            aria-owns={"menu-appbar"}
            aria-haspopup="true"
            color="inherit"
            onClick={showAvatarUploader}
            disabled={onUpdateAvatar === undefined}
            // style={{ width: 60, height: 60 }}
          >
            {user?.avatar ? <Avatar className={styles.avatar}
                                    src={user?.avatar} />
              : <AccountCircle
                className={styles.avatar}
                color={"disabled"}
              />
            }
          </IconButton>
        </Tooltip>

        <div className={styles.avatarName}>{`${user?.last_name || ""} ${user?.first_name || ""}`}</div>
        <div className={styles.avatarEmail}>{`${user?.email || ""}`}</div>
      </div>

      <div>
        <button
          className={styles.button}
          onClick={switchAccount}

        >
          {intl["account.switchAccount"] || "アカウントの切り替え"}
        </button>

        <button
          className={styles.button}
          onClick={onClickPlanManagement}
        >
          {intl["account.planmanagement"] || "プランの管理 "}
        </button>

        <button
          onClick={onClickAccount !== undefined ? onClickAccount : handleClickAccount}
          className={styles.button}
        >
          {intl["account.Account"] || "アカウント"}
        </button>

        <button
          onClick={onClickLogOut}
          className={styles.button}
        >
          {intl["account.logOut"] || "ログアウト"}
        </button>
        <DialogChangeAvatar openAvatarUploader={openAvatarUploader}
                            setOpenAvatarUploader={setOpenAvatarUploader}
                            user={user}
                            storage={storage}
                            onUpdateAvatar={onUpdateAvatar}
        />
      </div>
    </div>
  );
}

export default AccountPopup;

