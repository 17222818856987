import { useEffect, useState } from "react";
import { useCollection, useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { concat, findIndex, isUndefined, orderBy } from "lodash";
import { globalNavigationCollRef, userNavigationCollRef } from "../config/firebaseRef";

function useGlobalNavigations(user, db, service) {
  const [favoriteGeniam, setFavoriteGeniam] = useState([]);
  const [favoriteOther, setFavoriteOther] = useState([]);
  const [shortLink, setShortLink] = useState([]);
  const [globalAppsGeniam, setGlobalAppGeniam] = useState([]);
  const [globalOther, setGlobalOther] = useState([]);
  const [globalNavigationColl] = useCollectionDataOnce(
    db ?
      globalNavigationCollRef(db)
      : null
  );
  const [userGlobalNavi] = useCollection(
    (db && user.user_id) ?
      userNavigationCollRef(user.user_id, db)
      : null
  );
  useEffect(() => {
    if (!user?.user_id)
      return;
    if (!userGlobalNavi || !userGlobalNavi) return;
    const userGlobalNaviData = userGlobalNavi.docs.map(doc => ({ ...doc.data(), id: doc.id })) || [];
    if (!globalNavigationColl || globalNavigationColl.empty) return;
    const listAllNavigationGeniam = globalNavigationColl.map(item => {
      let idx = findIndex(userGlobalNaviData, { id: item.id });
      if (idx === -1) {
        return {
          ...item,
          global: true,
          shortLink: { [service]: false },
          geniamApp: true,
          favorite: { [service]: false }
        };
      }
      return {
        ...item, ...userGlobalNaviData[idx],
        geniamApp: true,
        global: !isUndefined(userGlobalNaviData[idx].global) ? userGlobalNaviData[idx].global
          : (isUndefined(userGlobalNaviData[idx].checked) ? true : userGlobalNaviData[idx].checked),

        shortLink: !isUndefined(userGlobalNaviData[idx].shortLink) ? userGlobalNaviData[idx].shortLink
          : { [service]: Boolean(userGlobalNaviData[idx][service]) }
        ,

        favorite: !isUndefined(userGlobalNaviData[idx].favorite) ? userGlobalNaviData[idx].favorite
          : { [service]: Boolean(userGlobalNaviData[idx].show) }
      };
    });
    const listAllOther = userGlobalNaviData.filter(i => !i.geniamApp).map((item, idx) => {
      return {
        ...item,
        global: !isUndefined(item.global) ? item.global
          : (isUndefined(item.checked) ? true : item.checked),

        shortLink: !isUndefined(item.shortLink) ? item.shortLink
          : { [service]: Boolean(item[service]) }
        ,

        favorite: !isUndefined(item.favorite) ? item.favorite
          : { [service]: Boolean(item.show) }

      };
    });
    const allApps = concat(listAllOther, listAllNavigationGeniam);
    setGlobalAppGeniam(orderBy(allApps, "index", "asc").filter(item => item?.global && item.geniamApp));
    setGlobalOther(orderBy(listAllOther, "index", "asc").filter(item => item?.global));
    setFavoriteGeniam(orderBy(allApps, "index", "asc").filter(item => item?.favorite[service] && item.geniamApp));
    setFavoriteOther(orderBy(listAllOther, "index", "asc").filter(item => item?.favorite[service]));
    setShortLink(orderBy(listAllNavigationGeniam, "index", "asc").filter(item => item?.shortLink[service]));
  }, [globalNavigationColl, userGlobalNavi]);
  return { globalOther, globalAppsGeniam, favoriteGeniam, favoriteOther, shortLink, globalNavigationColl };
}

export default useGlobalNavigations;
