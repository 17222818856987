import { filter } from "lodash";

export const handleSearchMeetings = (searchKey, list = []) => {
  if (!searchKey || !list.length) {
    return [];
  }
  return (filter(list, item => onValidSearch(item, searchKey)));

};
const onValidSearch = (item, key) => {
  if (!item)
    return false;
  return item.name?.toLowerCase()?.includes(key?.toLowerCase()) || memberSearch(item.members || [], key?.toLowerCase());

};
const memberSearch = (members = [], key) => {
  let result = false;
  members.forEach(mem => {
    if (mem?.email?.toLowerCase()?.includes(key?.toLowerCase()) || mem?.nickname?.toLowerCase()?.includes(key?.toLowerCase()) || mem?.first_name?.toLowerCase()?.includes(key?.toLowerCase()) || mem?.last_name?.toLowerCase()?.includes(key?.toLowerCase())) {
      result = true;
    }
  });
  return result;
};
