import moment from "moment";

export const setMomentLocale = (language) => {
  let locale = language.split("-")?.[0];
  if (locale === "en")
    locale = "en-gb";
  import(`moment/locale/${locale}`)
    .then(d => {
      moment.locale(locale);
    });
};
