import React, { useState } from "react";
import { LinearProgress, Popover } from "@material-ui/core";
import useNotifications from "../../hooks/useNotifications";
import { concat } from "lodash";
import InfiniteScroll from "react-infinite-scroller";
import CardContentNotice from "./CardContentNotice";
import { notificationsColRef } from "../../config/firebaseRef";
import styles from "../../styles/NotificationsPopup.module.scss";

function NotificationsPopup(props) {
  const { anchorEl, setAnchorEl, user, db, intl } = props;
  const [hasMore, setHasMore] = useState(true);
  const [notifications, setNotifications, lastVisible, setLastVisible] = useNotifications(user, db);
  const onNext = async () => {
    if (!lastVisible && !hasMore)
      return null;

    try {
      const nextNotificationColl = await notificationsColRef(user.user_id, db)
        .orderBy("createdAt", "desc")
        .startAfter(lastVisible)
        .limit(15)
        .get();

      let lastVisibleColl = nextNotificationColl.docs[nextNotificationColl.docs.length - 1];

      if (lastVisibleColl !== undefined) {
        let newData = nextNotificationColl.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        let newNotifications = concat(notifications, newData);
        setNotifications([...newNotifications]);
        setLastVisible(lastVisibleColl);
      } else {
        setHasMore(false);
      }

    } catch (e) {
      console.log(e.toString());
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      className={styles.rootNotificationsPopup}
    >
      <InfiniteScroll
        pageStart={0}
        loadMore={onNext}
        hasMore={hasMore}
        loader={<LinearProgress key={0} />}
        useWindow={false}
      >
        <div className="tracks">
          <CardContentNotice db={db} setAnchorEl={setAnchorEl} notifications={notifications} user={user}
                             intl={intl}
          />
        </div>
      </InfiniteScroll>

    </Popover>
  );
}

export default NotificationsPopup;
