import React, { useEffect, useState } from "react";
import { Avatar, Badge } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { AccountCircle } from "@material-ui/icons";
import { Popover, Tooltip } from "antd";
import AccountPopup from "./AccountPopup";
import { filter } from "lodash";
import useNotifications from "../../hooks/useNotifications";
import NotificationsPopup from "./NotificationsPopup";
import ListApps from "./ListApps";
import useGlobalNavigations from "../../hooks/useGlobalNavigations";
import ListAppsShow from "./ListAppsShow";
import { ReactComponent as ICONHX } from "../../assets/hxIcon.svg";
import { ReactComponent as AppIcon } from "../../assets/AppIcon.svg";
import { ReactComponent as LinkIcon } from "../../assets/LinkIcon.svg";
import { ReactComponent as IconNotification } from "../../assets/iconNotification.svg";
import { HX_CLOUD_DOMAIN } from "../../config/constants";
import { noticeDocRef } from "../../config/firebaseRef";
import { setMomentLocale } from "../../common/setMomentLocale";
import styles from "../../styles/NavigationGlobal.module.scss";

function NavigationGlobal({
                            user,
                            switchAccount,
                            onClickLogOut,
                            onClickAccount,
                            db,
                            storage,
                            service,
                            intl,
                            onClickSetting,
                            notification = true,
                            onClickLogin,
                            hiddenApp = false,
                            onUpdateAvatar
                          }) {
  const [number, setNumber] = useState(null);
  const [anchorElNotice, setAnchorElNotice] = useState(null);
  const [notifications] = useNotifications(user, db);
  const {
    globalOther,
    globalAppsGeniam,
    favoriteGeniam,
    favoriteOther,
    shortLink,
    globalNavigationColl
  } = useGlobalNavigations(user, db, service);
  useEffect(() => {
    if (notifications?.length !== 0) {
      let noticeNew = filter(notifications, n => n.isRead !== true);
      if (noticeNew.length !== 0) {
        setNumber(noticeNew.length);
        return;
      }
      setNumber(null);
    }
  }, [notifications]);
  const theme = createTheme({
    typography: {
      fontFamily: [
        "Montserrat"
      ].join(",")
    },
    props: {
      primaryColor: user?.colors?.primary || "#1790FF",
      secondaryColor: user?.colors?.secondary || "#690003",
      // or add more props to handle theme
      MuiButtonBase: {
        disableRipple: true // No more ripple, on the whole application 💣!
      }
    }
  });
  useEffect(() => {
    setMomentLocale(user?.language || "ja-jp");
  }, [user?.language]);
  const onClickNotice = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorElNotice(event.currentTarget);
    if (!number) {
      return null;
    }
    try {
      const batch = db.batch();
      const newNotice = filter(notifications, n => n.isRead !== true);
      if (newNotice.length !== 0) {
        newNotice.forEach(notice => {
          const noticeRef = noticeDocRef(user.user_id, db, notice.id);
          batch.set(noticeRef, { isRead: true }, { merge: true });
        });
        await batch.commit();
      }
    } catch (e) {
      console.log(e.toString());
    }
  };

  useEffect(() => {
    window.process = { ...window.process };
  }, []);
  // if (!user?.user_id) return null;
  return (
    <ThemeProvider theme={theme}>

      <div className={styles.globalNavigation}>
        {
          !user?.user_id ?
            <div className={styles.flex}>
              <button
                className={styles.globalNavigationButtonLogin}
                onClick={onClickLogin}
              >ログイン
              </button>
              <Popover placement="bottomRight"
                       content={
                         <ListApps
                           user={user}
                           title={intl["app.show.blank"]}
                           listApps={globalNavigationColl}
                           onClickSetting={onClickSetting}
                           noResult={intl["appy.noResult"]}
                           hiddenApp={hiddenApp}
                           listOther={[]}
                         />
                       }
                       trigger="click"
                       destroyTooltipOnHide={true}
                       overlayClassName={styles.globalNavigationListApps}
              >
                <button className={styles.globalNavigationIconButton}>
                  <AppIcon />
                </button>
              </Popover>
              <Tooltip
                destroyTooltipOnHide={true}
                placement={"bottomRight"}
                title={"HXcloud Geniam 全サービス概要"}
              >
                <button
                  aria-owns={"menu-appbar"}
                  className={styles.globalNavigationIconButton}
                  onClick={() => {
                    window.open(HX_CLOUD_DOMAIN, "_blank");
                  }}
                >
                  <ICONHX />
                </button>
              </Tooltip>
            </div>
            :
            <div className={styles.flex}>
              {
                hiddenApp ? null :
                  <ListAppsShow
                    shortLink={shortLink}
                    intl={intl}
                    user={user}
                    db={db}
                  />
              }

              {
                hiddenApp ? null
                  :
                  <Popover placement="bottomRight"
                           content={
                             <ListApps
                               user={user}
                               title={intl["app.show.blank"]}
                               listApps={favoriteGeniam}
                               onClickSetting={onClickSetting}
                               noResult={intl["appy.noResult"]}
                               hiddenApp={hiddenApp}
                               listOther={favoriteOther}
                             />
                           }
                           trigger="click"
                           destroyTooltipOnHide={true}
                           overlayClassName={styles.globalNavigationListApps}
                  >
                    <button className={styles.globalNavigationIconButton}>
                      <LinkIcon />
                    </button>
                  </Popover>
              }

              <Popover placement="bottomRight"
                       content={
                         <ListApps
                           user={user}
                           title={intl["app.show.blank"]}
                           listApps={globalAppsGeniam}
                           onClickSetting={onClickSetting}
                           noResult={intl["appy.noResult"]}
                           hiddenApp={hiddenApp}
                           listOther={[]}
                         />
                       }
                       trigger="click"
                       destroyTooltipOnHide={true}
                       overlayClassName={styles.globalNavigationListApps}
              >
                <button className={styles.globalNavigationIconButton}>
                  <AppIcon />
                </button>
              </Popover>
              {
                (notification && !hiddenApp) ?
                  <button
                    id={"calendar-top-bar-right-notice"}
                    onClick={onClickNotice}
                    className={styles.globalNavigationIconButton}
                  >
                    {
                      number !== null ?
                        <Badge badgeContent={number}
                               className={styles.globalNavigationBadge}
                               max={99}
                        >
                          <IconNotification style={{ fontSize: 35 }} />
                        </Badge>
                        :
                        <Badge>
                          <IconNotification style={{ fontSize: 35 }} />
                        </Badge>
                    }
                  </button>
                  : null
              }


              <Popover placement="bottomRight"
                       content={
                         <AccountPopup
                           user={user}
                           onClickLogOut={onClickLogOut}
                           switchAccount={switchAccount}
                           onClickAccount={onClickAccount}
                           intl={intl}
                           storage={storage}
                           onUpdateAvatar={onUpdateAvatar}
                         />}
                       trigger="click"
                       destroyTooltipOnHide={true}
                       overlayClassName={styles.globalNavigationAccountPopupGlobal}
              >
                <button
                  className={styles.globalNavigationIconButton}
                >
                  {user?.avatar ? <Avatar
                    alt={user?.nickname}
                    src={user?.avatar}
                  /> : <AccountCircle style={{ fontSize: 35 }} />
                  }
                </button>
              </Popover>


              <Tooltip
                destroyTooltipOnHide={true}
                placement={"bottomRight"}
                title={"HXcloud Geniam 全サービス概要"}
              >
                <button
                  aria-owns={"menu-appbar"}
                  className={styles.globalNavigationIconButton}
                  onClick={() => {
                    window.open(HX_CLOUD_DOMAIN, "_blank");
                  }}
                >
                  <ICONHX />
                </button>
              </Tooltip>
              {
                notification && <NotificationsPopup
                  anchorEl={anchorElNotice}
                  setAnchorEl={setAnchorElNotice}
                  notifications={notifications}
                  user={user}
                  db={db}
                  intl={intl}
                />
              }
            </div>

        }


      </div>
    </ThemeProvider>
  );
}

export default NavigationGlobal;

