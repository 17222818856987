import React, { useEffect, useState } from "react";
import { Input, Tabs } from "antd";
import ItemAppy from "./ItemAppy";
import { useDebounce } from "react-use";
import { handleSearchMeetings } from "../../common/searchMeeting";
import styles from "../../styles/MeetingList.module.scss";

const { TabPane } = Tabs;
const { Search } = Input;

function MeetingList({ intl, listArchive, listPrimary, listTotal, name, timeZone }) {
  const [searchKey, setSearchKey] = useState(null);
  const [searchList, setSearchList] = useState([]);
  const [searchDebounce, setSearchDebounce] = useState(null);
  const [tab, setTab] = useState(1);
  useDebounce(() => {
    if (!searchKey || searchKey === "" || searchKey.replace(/\s+/g, "") === "") {
      return setSearchDebounce(null);
    }
    return setSearchDebounce(searchKey);
  }, 500, [searchKey]);

  useEffect(
    () => {
      if (!searchDebounce || searchDebounce === "" || searchDebounce.replace(/\s+/g, "") === "") {
        setSearchList([]);
        return;
      }
      return setSearchList(handleSearchMeetings(searchDebounce, listTotal));
    },
    [searchDebounce]
  );
  return (
    <div className={styles.MeetingList}>
      <div className={styles.title}>{name}</div>
      <Search
        placeholder={intl[`event.meeting.search`] || "search"}
        enterButton className={styles.search}
        onChange={(e) => setSearchKey(e.target.value)}
      />
      {
        (!searchKey || searchKey === "" || searchKey.replace(/\s+/g, "") === "") ?
          <div>
            <div className={styles.tabGroup}>
              <button
                onClick={() => setTab(1)}
                className={tab === 1 ? styles.tabActive : styles.tab}>{intl["event.meeting.main"] || "Live"}</button>
              <button
                onClick={() => setTab(2)}
                className={tab === 2 ? styles.tabActive : styles.tab}
              >{intl["event.meeting.extra"] || "Archive"}</button>
            </div>
            <div className={styles.content}>
              {
                tab === 1 ?
                  <div>
                    {
                      listPrimary.length > 0 ? <div>
                          {listPrimary.map((item, index) =>
                            <  ItemAppy timeZone={timeZone} name={name} item={item} key={index} />
                          )}
                        </div>
                        :
                        <div className={styles.noResult}>{intl["appy.noResult"] || "no data"}</div>
                    }
                  </div>
                  :
                  <div>
                    {
                      listArchive.length > 0 ? <div>
                          {listArchive.map((item, index) =>
                            <ItemAppy timeZone={timeZone} name={name} item={item} key={index} />
                          )}
                        </div>
                        :
                        <div className={styles.noResult}>{intl["appy.noResult"] || "no data"}</div>
                    }
                  </div>
              }

            </div>
          </div>

          :
          <div className={styles.content}>
            {
              searchList.length > 0 ?
                <div className={styles.listSearch}>
                  {
                    searchList.map((item, index) =>
                      <ItemAppy timeZone={timeZone} name={name} item={item} key={index} />
                    )
                  }
                </div>
                :
                <div className={styles.noResult}>{intl["appy.noResult"] || "no data"}</div>
            }
          </div>


      }

    </div>
  );
}

export default MeetingList;

