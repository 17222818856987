import React from "react";
import { Popover } from "antd";
import faviconFetch from "favicon-fetch";
import MeetingList from "./MeetingList";
import { ReactComponent as IMG1on1SVG } from "../../assets/icon1on1.svg";
import { ReactComponent as IMGGroupsSVG } from "../../assets/iconGroup.svg";
import useAppyGroup from "../../hooks/useAppyGroup";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { APPOINTMENT_DOMAIN } from "../../config/constants";
import styles from "../../styles/ListAppsShow.module.scss";
import getUserTimezone from "../../common/getUserTimezone";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1
};

function DivContainer({ children }) {
  return (
    <div style={{ display: "flex" }}>
      {children}
    </div>
  );
}

function ListAppsShow({ shortLink, intl, user, db }) {
  const {
    listPrimary,
    listArchive,
    listMeetingGroup,
    listLiveUrl,
    listArchiveUrl,
    listSettingShareUrl
  } = useAppyGroup(user, db);
  const linkAppointment = (to) => {
    let link = `${APPOINTMENT_DOMAIN}/${to}`;
    window.open(link, "_blank");
  };
  const timeZone = getUserTimezone(user);

  const Container = shortLink?.length < 5 ? DivContainer : Slider;
  if (!shortLink?.length) return null;

  return (
    <Container {...settings} className={styles.listAppsShow}>
      {
        shortLink.map((item, index) => {
          if (item.id === "appy") {
            return (
              <Popover
                overlayClassName={styles.popoverAppy}
                arrowPointAtCenter={true}
                // overlayInnerStyle={{padding: 0}}
                content={
                  <MeetingList
                    name={"group"}
                    listPrimary={listPrimary}
                    listArchive={listArchive}
                    intl={intl}
                    listTotal={listMeetingGroup}
                    timeZone={timeZone}
                  />
                }
                destroyTooltipOnHide={true}
                key={item.id}
              >
                <button
                    onClick={() => linkAppointment("event")}
                    className={styles.iconButton}
                  >
                    <IMGGroupsSVG />
                  </button>
                </Popover>

              );
            }
            if (item.id === "1on1") {
              return (
                <Popover
                  overlayClassName={styles.popoverAppy}
                  arrowPointAtCenter={true}
                  content={
                    <MeetingList
                      name={"1on1"}
                      listPrimary={listLiveUrl}
                      listArchive={listArchiveUrl}
                      intl={intl}
                      listTotal={listSettingShareUrl}
                      timeZone={timeZone}
                    />
                  }
                  destroyTooltipOnHide={true}
                  key={item.id}
                >
                  <button
                    onClick={() => linkAppointment("shareUrl")}
                    className={styles.iconButton}
                  >
                    <IMG1on1SVG />
                  </button>
                </Popover>
              );
            } else
              return (
                <Popover
                  destroyTooltipOnHide={true}
                  content={item.name} key={item.id}>
                  <button
                    onClick={() => {
                      window.open(item.url, "_blank");
                    }}
                    className={styles.iconButton}
                  >
                    <img
                      src={item.icon ? item.icon : faviconFetch({ uri: item.url })}
                      alt="" />
                  </button>
                </Popover>
              );
          }
        )
      }
    </Container>
  );
}

export default ListAppsShow;
