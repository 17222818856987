export const IS_PROD = (process && (process.env.REACT_APP_ENV || process.env.NODE_ENV)) === "production";
export const SUBDOMAIN_APPOINTMENT = IS_PROD ? "appy" : "appy-stg";
export const CALENDAR_COLLECTION = IS_PROD ? "calendar" : "calendar-stg";
export const MEETING_COLLECTION = IS_PROD ? "meetings" : "meetings-stg";
export const HX_CLOUD_DOMAIN = IS_PROD ? "https://hx-cloud.geniam.com" : "https://hx-cloud-stg.geniam.com";
export const APPOINTMENT_DOMAIN = IS_PROD ? "https://appy.geniam.com" : "https://appy-stg.geniam.com";
export const MILESTONE_DOMAIN = IS_PROD ? "https://milestone.geniam.com/" : "https://milestone-stg.geniam.com/";
export const GLOBALNAVIGATION_COLLECTION = IS_PROD ? "GlobalNavigation" : "GlobalNavigation-stg";
export const USER_COLLECTION = IS_PROD ? "users" : "users-stg";
export const RECOMMEND_COLLECTION = IS_PROD ? "recommend" : "recommend-stg";
export const CHANGE_ACCOUNT_INFO_URL = IS_PROD ? "https://api.geniam.com/change-account-info" : "https://api-stg.geniam.com/change-account-info";

