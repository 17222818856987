import React from "react";
import moment from "moment-timezone";
import { APPOINTMENT_DOMAIN } from "../../config/constants";
import styles from "../../styles/ItemAppy.module.scss";

function ItemAppy({ item, name, timeZone }) {
  const onClickItem = (name, item) => {
    if (name === "group") {
      window.open(`${APPOINTMENT_DOMAIN}/event?id=${item?.id}`, "_blank");
    }
    if (name === "1on1") {
      let tabShareUrl = moment(item?.exp).isAfter(Date.now()) ? "live" : "archive";
      window.open(`${APPOINTMENT_DOMAIN}/shareUrl?idShare=${item?.id}&tabShareUrl=${tabShareUrl}`, "_blank");
    }
  };
  return (
    <div
      key={item.id}
      className={styles.ItemAppy}
      onClick={(event) => {
        event.stopPropagation();
        onClickItem(name, item);
      }}
    >
      <div className={styles.title}>{name === "1on1" ? item?.nameSetting : item?.name}</div>
      <div
        className={styles.time}>{name === "1on1" ?
        `${moment(item?.exp).tz(timeZone).format("LL")} (${moment(item?.exp).tz(timeZone).format("ddd")}) ${moment(item?.exp).tz(timeZone).format("HH:mm")}`
        :
        `${moment(item.updatedAt).tz(timeZone).format("LL")} (${moment(item.updatedAt).tz(timeZone).format("ddd")}) ${moment(item?.updatedAt).tz(timeZone).format("HH:mm")}`}</div>
    </div>
  );
}

export default ItemAppy;

