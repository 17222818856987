import { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { notificationsColRef } from "../config/firebaseRef";

function useNotifications(user, db) {
  const [notifications, setNotifications] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [noticeColl] = useCollection(
    user?.user_id ?
      notificationsColRef(user.user_id, db)
        .orderBy("createdAt", "desc")
        .limit(15)
      : null
  );

  useEffect(() => {
    if (!noticeColl || noticeColl.empty || !user?.user_id) return;
    const data = noticeColl.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    setLastVisible(noticeColl.docs[noticeColl.docs.length - 1]);
    setNotifications(data);
  }, [noticeColl]);

  return [notifications, setNotifications, lastVisible, setLastVisible];
}

export default useNotifications;
