import React from "react";
import { Avatar } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import moment from "moment-timezone";
import { isEmpty } from "lodash";
import { APPOINTMENT_DOMAIN, CALENDAR_COLLECTION } from "../../config/constants";
import styles from "../../styles/CardContentNotice.module.scss";
import getUserTimezone from "../../common/getUserTimezone";

function CardContentNotice({ notifications, user, setAnchorEl, db, intl }) {
  const timeZone = getUserTimezone(user);
  const handleClick = async (notice) => {
    if (!user)
      return;
    let noticeOld = notice;
    try {
      if (!noticeOld.isClick) {
        const noticeRef = db.doc(`${CALENDAR_COLLECTION}/${user.user_id}/notifications/${notice.id}`);
        await noticeRef.set({ isClick: true }, { merge: true });
      }
      setAnchorEl(null);
      let link;
      if (noticeOld.from && noticeOld.to && noticeOld.by) {
        link = `${APPOINTMENT_DOMAIN}/event?id=${noticeOld.meetingId}&from=${noticeOld.from}&by=${noticeOld.by}&to=${noticeOld.to}`;
        window.open(link, "_blank");
        return;
      }
      if (notice.scheduleAjustment) {
        link = notice.scheduleId ? `${APPOINTMENT_DOMAIN}/shareUrl?idShare=${notice.scheduleId}` : `${APPOINTMENT_DOMAIN}/shareUrl`;
        window.open(link, "_blank");
        return;
      }

      link = `${APPOINTMENT_DOMAIN}/event?id=${noticeOld.meetingId}`;
      window.open(link, "_blank");


      //
      // if (noticeOld.scheduleAjustment) {
      //     link = "/"
      //     setView('CustomDay')
      //     setRefetchDateNow(noticeOld.start)
      // }

      // history.push(link)
    } catch (e) {
      console.log(e.toString());
    } finally {

    }

  };
  return (
    <div className={styles.CardContentNotice}>
      {
        notifications && notifications.length !== 0 ? notifications.map((notice, index) => {
            const start = moment(notice?.start).tz(timeZone).locale(user?.language).format("LT");
            const end = moment(notice?.end).tz(timeZone).locale(user?.language).format("LT");
            const day = moment(notice.start).locale(user?.language).format("ddd");
            const time = moment(notice.createdAt).locale(user?.language).fromNow();
            const times = `${moment(notice.start).tz(timeZone).locale(user?.language).format("LL")} (${day}) ${start}~${end}`;
            return (
              <div
                key={notice.id}
                onClick={() => handleClick(notice)}
                className={notice.isClick === false ? styles.notificationsRead : styles.notifications}
              >
                <div className={styles.item}>
                  <Avatar src={notice.avatar}>{notice?.nickname?.[0]?.toUpperCase()}</Avatar>
                  <div className={styles.cardContent}>
                    <div className={styles.name}>{notice.name}</div>
                    <div className={styles.info}>
                      {
                        notice.messageId ?
                          <div className={styles.content}>
                            <span className={styles.nickName}>{notice.nickname}</span>
                            &nbsp;
                            {isEmpty(intl) ? `${notice.messageId}` : intl[`${notice.messageId}`]}
                          </div>
                          :
                          <div className={styles.content}>
                            {notice.content}
                          </div>
                      }
                      {
                        notice?.start || notice?.end ?
                          <React.Fragment>
                            <div
                              className={styles.time}><AccessTimeIcon />{times}
                            </div>
                            <div
                              className={styles.createdAt}>
                              {time}
                            </div>
                          </React.Fragment>
                          :
                          <div
                            className={styles.createdAt}> {time}
                          </div>
                      }

                    </div>
                  </div>
                </div>

              </div>
            );
          })
          :
          <div style={{ textAlign: "center", height: "50px" }}>
            <div style={{ marginTop: 20, color: "red" }}>No notification found</div>
          </div>
      }
    </div>
  );
}

export default CardContentNotice;

